.sidebar {
    position: relative;
    z-index: 12;

    display: block;
    width: var(--sidebar-left-size-desktop);

    color: #fff;

    background: var(--dark-color);

    &__wrap{
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        width: var(--sidebar-left-size-desktop);
        height: 100%;
        background-color: var(--dark-color);

        transition: width .3s;

        @media (max-width: 1024px) {
            width: 200px;
        }
    }

    &--closed &__wrap{
        width: 100px;
    }

    &__switcher{
        position: absolute;
        bottom: 40px;
        right: -20px;
        z-index: 10;

        display: flex;
        align-self: center;
        justify-content: center;

        width: 40px;
        height: 40px;

        background-color: var(--dark-color);
        border-radius: 100%;
        
        cursor: pointer;

        & svg{
            width: 12px;

            transition: transform .3s;
        }
    }

    &--closed &__switcher{
        & svg{
            transform: rotate(180deg);
        }
    }

    &__logo{
        position: relative;
        z-index: 12;

        display: flex;
        align-items: center;
        justify-content: center;

        height: 84px;
        padding: 8px 16px;

        transition: padding .3s;

        & svg{
            max-height: 45px;
        }
    }

    &--closed &__logo{
        padding: 8px;
    }

    &__content{
        display: flex;
        flex-direction: column;
        grid-gap: 16px;

        width: var(--sidebar-left-size-desktop);
        height: calc(100% - 64px);
        padding: 16px 0;
    }

    &-menu{
        &__item{
            position: relative;

            margin-bottom: 10px;

            font-family: var(--secondary-font);
            color: var(--white);
        }

        &__link{
            display: flex;
            align-items: center;
            
            padding: 16px 16px 16px 30px;

            &.active{
                color: var(--secondary-color);

                &::before{
                    content: '';

                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;

                    width: 4px;

                    background-color: var(--secondary-color);
                }
            }

            & svg{
                width: 24px;
                margin-right: 16px;
            }
        }
    }

    &--closed &-menu{
        &__item{
            &:not(:last-of-type){
                &::before{
                    opacity: 0;
                }
            }
        }
        &__link{
            flex-direction: column;

            & svg{
                margin: 0 auto 8px;
            }
        }
        &__label{
            font-size: 10px;
            text-transform: uppercase;
        }
    }

    &-user{
        padding: 16px;

        text-align: center;

        background-color: #2c2f34;

        .loader{
            margin-bottom: 20px;
        }

        & .button{
            &:not(:last-of-type){
                margin-bottom: 16px;
            }
        }

        &__name{
            display: flex;
            justify-content: center;

            margin-bottom: 20px;

            font-size: 18px;
            text-transform: uppercase;
        }

        &__exit{
            text-align: center;
            color: #c1c2c4;

            cursor: pointer;
        }
    }

    
}