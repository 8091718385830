.searchBar{
    width: 100%;
    max-width: 396px;

    i{
        color: #C3C6CD;
    }
    
    .input{
        width: 100%;
    }
}