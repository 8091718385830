.map{
    .main{
        padding: 84px 0 0 0;
    }

    &__wrap{
        display: flex;
    }

    &__block{
        position: relative;

        width: 100%;
        height: calc(100vh - var(--header-size-desktop));
    }

    &__block &__container{
        position: absolute;
        top: 0;
        bottom: 0;
    
        width: 100%;
        height: calc(100vh - var(--header-size-desktop));
    }


    &--admin .main{
        padding-top: 100px;
    }


    &__container{
        height: 100%;
    }

    &--admin &__container{
        height: calc(100vh - 140px);
    }
    

    &--2{
        pointer-events: none;
    }

    &__canvas{
        position: absolute;

        opacity: 0;        

        &--with-animation{
            transition: opacity .5s ease-out;
        }

        &--show {
            opacity: 0.8;
            pointer-events: none;
        }
    }

    .mapboxgl-marker{
        z-index: 1;
        
        img {
            height: 25px;
            width: 25px;
        }
    }

    &-legend{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        padding: 8px 8px 0;

        border-radius: 0 0 10px 0;

        overflow: hidden;

        &::before{
            content: '';

            position: absolute;
            inset: 0;
            z-index: -1;

            background: var(--white);
            opacity: .5;
        }

        &__title{
            margin-bottom: 4px;

            font-size: 11px;
        }

        &__list{
            display: inline-block;

            max-width: 200px;       
        }

        &__item{
            display: flex;
            align-items: center;
        }
        
        &__color{
            display: block;

            width: 16px;
            height: 16px;
        }

        &__label{
            margin-left: 5px;
            font-size: 11px;
            line-height: 12px;
        }
    }
    
}

.screen{
    position: absolute;
    z-index: 11;
    // z-index: 15;

    top: 200px;
    // top: 0;
    left: -99999em;
    // left: -200px;

    width: 1024px;
    height: 1024px;

    // background-color: red;

    &__container{
        position: absolute;
        inset: 0;

        .mapboxgl-control-container{
            display: none;
        }
    }

    .map__canvas{
        inset: 0;
    }
}