.main .mapboxgl{
    &-popup{
        &-content{
            padding: 8px;
            margin: -1px 0;

            border-radius: 8px;
        }

        &-close{
            &-button{
                top: -10px;
                right: -10px;

                width: 20px;
                height: 20px;

                text-align: center;

                border-radius: 100%;
                background-color: var(--white);

                &:hover{
                    background-color: var(--white);
                }
            }
        }

        &--title {
            margin-top: -20px;
            .mapboxgl-popup-content{
                padding: 4px 4px;

                font-size: 11px;
                line-height: 14px;

                border-radius: 4px;
                background-color: #ebebeb;
            }

            .mapboxgl-popup-tip{
                display: none;
            }
        }
    }
}