.button {
    position: relative;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 40px;
    padding: 0 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    color: #fff;
    white-space: nowrap;
    letter-spacing: 0.02em;
    border-radius: 20px;
    box-sizing: border-box;
    user-select: none;
    cursor: pointer;
    transition: all 0.5s;
    grid-gap: 8px;
    border: 2px solid;

    i {
        width: 24px;
        height: 24px;
        font-size: 24px;
        text-shadow: none;
    }

    &:hover {
        transition: all 0.5s;
    }

    &.disabled {
        cursor: default;
    }

    &--t1{
        background-color: var(--primary-color);
        border-color: var(--primary-color);
    }

    &--t2{
        background-color: var(--secondary-color);
        border-color: var(--secondary-color);
    }

    &--t3{
        background-color: transparent;
        border-color: var(--input-placeholder-color);
        color: var(--input-border);
    }

    &--disabled{
        opacity: .3;
        cursor: default;
    }

    &--centered{
        display: flex;
        margin: 0 auto;
    }

    &__loader{
        position: absolute;
        inset: -2px;

        display: flex;
        align-items: center;
        justify-content: center;

        background-color: rgba(0, 0, 0, .50);
        border-radius: 20px;

        transition: .3s;

        cursor: default;
    }


}

.button--s1 {
    width: 100%;
}

.button--s2 {
    width: 180px;
}

.button--s3 {
    width: 200px;
}


