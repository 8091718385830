.modal{
    position: relative;

    width: 100%;
    max-width: 800px;
    height: auto; 
    padding: 20px;
    margin: 0 auto;

    border: 1px solid rgb(204, 204, 204);
    background: var(--white);
    border-radius: 10px;

    overflow: auto;

    &__overlay{
        position: fixed;
        inset: 0;
        z-index: 999;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__close{
        position: absolute;
        top: 20px;
        right: 20px;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 30px;
        height: 30px;

        font-family: var(--secondary-font);
        font-size: 16px;
        text-transform: uppercase;

        cursor: pointer;
    }
}