:root {
    --primary-color: #009FA9;
    --secondary-color: #FFB74C;
    --background-color: #f7f7f7;
    --dark-color: #3A3A3A;
    --white: #fff;
    --title-color: #0E4563;
    --input-border: #aaa;
    --input-placeholder-color: #C3C6CD;
    --primary-font: 'Roboto', sans-serif;
    --secondary-font: 'Montserrat', sans-serif;
    --primary-shadow: 0 -4px 23px 0px rgba(14, 69, 99, 0.15);
}
  

  