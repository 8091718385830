.admin{
	&-stat{
        display: flex;
        align-items: center;

        padding: 8px 30px;

        background-color: var(--background-color);

        @media (max-width: 1280px) {
            flex-wrap: wrap;
        }

        li{
            position: relative;

            @media (max-width: 1280px) {
                margin-bottom: 15px;
            }

            &:not(:last-of-type){
                min-width: 35px;
                margin-right: 16px;

                &::after{
                    content: '';

                    position: absolute;
                    top: 50%;
                    right: -10px;

                    width: 2px;
                    height: 15px;

                    background-color:#ccc;

                    transform: translateY(-50%);
                }
            }

            & > span {
                position: absolute;
                top: -14px;

                font-size: 10px;
                line-height: 18px;
                white-space: nowrap;
            }

            & > div{
                height: 24px;
                line-height: 24px;
            }
        }

        &--radius{
            max-width: 90px;
        }

        &--coord{
            max-width: 80px;
        }
    }

    &-map{
        display: flex;
        align-items: center;

        &__id{
            margin-right: 20px;
        }
    }

    &-options{
        display: flex;
        
        margin-left: 50px;

        &__item{
            &:not(:last-of-type){
                margin-right: 20px;
            }
        }
    }
}