.form{
	&-row{
		margin-bottom: 20px;
	}
	&-element{
		
	}
	&-label{
		display: block;

		width: 100%;
		padding: 8px 0;

		font-weight: 600;
		line-height: 21px;
		text-align: left;
		color: var(--title-color);
	}

	&-error{
		font-size: 12px;
		color: red;
	}
}