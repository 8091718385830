.checkbox {
  text-align: left;

  input {
    display: none;

    height: 0;

    visibility: hidden;

    & + label {
      position: relative;

      display: inline-block;
      width: auto;
      padding-left: 30px;
      min-height: 24px;

      line-height: 24px;

      cursor: pointer;

      &::before {
        content: '';

        position: absolute;
        top: 0;
        left: 0;

        width: 22px;
        height: 22px;

        background-color: #fff;
        border-radius: 5px;
        border: 1px solid #ccc;
      }

      &::after {
        content: '';

        position: absolute;
        top: 11px;
        left: 7px;

        width: 8px;
        height: 4px;

        background: transparent;
        border-bottom: 2px solid transparent;
        border-left: 2px solid transparent;
        border-color: #161616;
        border-radius: 0;

        transform: translateY(-50%) rotate(-45deg);
        outline: none;
        opacity: 0;
      }
    }

    &:checked + label::after {      
      opacity: 1;
    }

    &:disabled + label {
      cursor: default;
    }

    &:disabled + label::before {
      background: #717171;
    }
  }
}
