.formsPage{
    display: flex;
    flex-direction: column;
    align-items: center;

    max-width: 640px;
    padding: 60px;
    margin: 80px auto;

    background-color: var(--white);
    border-radius: 20px;
    box-shadow: var(--primary-shadow);

    &__icon{
        margin-bottom: 16px;

        &--login{
            color: #FF9800;
        }

        &--registration{
            color: #009FA9;
        }
    }

    &__title{
        margin-bottom: 20px;

        font-size: 28px;
        font-weight: 700;
        line-height: 32px;
        color: var(--title-color);
    }

    &__form{
        width: 100%;
    }
}