.input {
  position: relative;

  display: inline-flex;
  align-items: center;

  width: 100%;
  padding: 8px 16px;

  border: 1px solid var(--input-border);
  border-radius: 100px;

  input[type='text'],
  input[type='password'],
  input[type='email'],
  input[type='tel'],
  input[type='search'],
  input[type='date']{
    position: relative;

    display: flex;

    width: 100%;
    padding: 0 8px;
    
    font-size: 15px;
    line-height: 1.5;		

    background: none;
    border: none;

    transition: all 0.3s ease;

    &:focus{
      outline: none;
    }
  }
}
  
  