.tabs{
    &__list{
        display: flex;

        margin-bottom: 20px;
    }

    &__item{
        position: relative;

        margin-right: 42px;

        color: var(--dark-color);

        cursor: pointer;

        &:not(:last-of-type){
            &::before{
                content: '';

                position: absolute;
                top: 0;
                bottom: 0;
                right: -20px;

                width: 2px;

                background-color: var(--input-border);
            }
        }
        
        &--active{
            color: var(--primary-color);

            cursor: default;
        }
    }
    
    &__content{
        margin-bottom: 20px;
    }

    &__block{
        &--visible {
            display: block;
        }
    
        &--hidden {
            display: none;
        }

        &:not(:last-of-type){
            margin-bottom: 20px;
        }
    }
}