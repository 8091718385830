.loader {
    &__container{
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;
    }

    &--content{
        position: absolute;
        inset: 0;
        z-index: 9;

        background-color: var(--background-color);
    }

    &--content &__container{
        position: absolute;
        inset: 40px 0 0 0;
    }

    &--fullscreen{
        position: fixed;
        inset: 0;
        z-index: 999;

        background-color: var(--white);
    }

    &--fullscreen &__container{
        position: absolute;
        inset: 40px 0 0 0;
    }

    &--inline{
        display: inline-flex;

        margin: 0 10px;
    }

    &--inline &__container{
        position: relative;
        inset: 0;        

        width: 26px;
        height: 6px;
    }

    &--inline.loader--big &__container{
        width: 100%;
        height: 100%;
    }

    &__spinner{        
        display: grid;
        --c: radial-gradient(farthest-side,#4e4e4e 90%,#0000);
        background: 
            var(--c) left, 
            var(--c) right;
        background-repeat: no-repeat;

        &::before,
        &::after{
            content: "";

            grid-area: 1/1;
            margin: auto;
            border-radius: 50%;

            background: #4e4e4e;

            animation: loader 1s infinite linear;
        }

        &::after{
            --s:-1;
            animation-delay: -.5s;
        }
    }

    &--big &__spinner{
        width: 52px;
        height: 12px;
        
        background-size: 12px 12px;        

        &::before,
        &::after {            
            width: 12px;
            height: 12px;
           
            transform-origin:-12px 50%;
        }
        
        &::after {
            transform-origin:calc(100% + 12px) 50%;
        }
    }

    &--small &__spinner{
        width: 26px;
        height: 6px;
        background-size: 6px 6px;

        &::before,
        &::after {
            width: 6px;
            height: 6px;

            transform-origin:-6px 50%;
        }
        &::after {
            transform-origin: calc(100% + 6px) 50%;
        }
    }  

    &--light &__spinner{        
        --c: radial-gradient(farthest-side,#a09f9f 90%,#0000);
       
        &::before,
        &::after{
            background: #a09f9f;
        }
    }

    &--white &__spinner{        
        --c: radial-gradient(farthest-side,#fff 90%,#0000);
       
        &::before,
        &::after{
            background: #fff;
        }
    }
}
          
@keyframes loader {
    58%,
    100% {transform: rotate(calc(var(--s,1)*1turn))}
}
