.pl{
    padding: 30px 0;

    &:not(:last-of-type){
        border-bottom: 1px solid var(--input-placeholder-color);
    }

    &-list{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr; 
        grid-gap: 30px 20px;

        margin-bottom: 30px;

        &__link{
            display: flex;
            flex-direction: column;
        }

        &__img{
            margin-bottom: 4px;

            img{
                width: 100%;
                object-fit: cover;
            }
        }

        &__title{
            font-size: 18px;
            font-weight: 700;
        }

        &__subtitle{
            font-size: 14px;
            font-style: italic;
        }
    }
    
}