.header{
    position: fixed;
    top: 0;
    left: var(--sidebar-left-size-desktop);
    right: 0;
    z-index: 11;

    padding: 20px 30px;

    height: 84px;

    background-color: var(--background-color);
    box-shadow: var(--primary-shadow);

    @media (max-width: 1024px) {
        left: 200px;
    }

    .wrap-sidebar--closed &{
        left: 75px;
    }

    &__wrap{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        width: 100%;
    }

    &__left{
        display: flex;
        align-items: center;
        
        width: 50%;
    }

    &-menu{
        display: flex;
        align-items: center;

        margin-left: 30px;

        font-family: var(--secondary-font);

        &__item{
            &:not(:last-of-type){
                margin-right: 30px;
            }
        }

        &__link{
            color: var(--dark-color);
        }
    }

    &__right{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        
        width: 50%;
    }

    &-buttons{
        display: flex;

        .button{
            min-width: 110px;

            &:not(:last-of-type){
                margin-right: 20px;
            }
        }
    }

    &-user{
        display: flex;
    }
}