.screenshotContainer{
    position: absolute;
    top: -99999em;
    left: -99999em;
    z-index: -111;

    width: 1200px;
    padding: 20px 0 20px 20px;

    background-color: var(--background-color);
}