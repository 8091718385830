.socialSharingBlock{
    &__row{
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:not(:last-of-type){
            margin-bottom: 20px;
        }

        &--start{
            justify-content: flex-start;

            .socialSharingBlock__item{
                width: auto;
            }
        }

        &--loader{
            justify-content: center;

            padding: 30px 0;
        }
    }

    &__item{
        width: 100%;

        &:not(:last-of-type){
            margin-right: 20px;
        }
    }

    &__image{
        display: flex;
        align-items: center;
        justify-content: center;
        
        height: 758px;
    }

    &__preview{
        margin: 20px 0;
    }
}