.profile{
    &-info{
        display: flex;

        &__avatar{
            width: 100%;
            max-width: 120px;
            margin-right: 20px;

            color: var(--input-border);
        }

        &__user{
            margin-bottom: 16px;

            &-name{
                font-size: 20px;
                font-weight: bold;
            }

            &-from{
                margin-bottom: 12px;
            }
        }

        &__stat{
            display: flex;

            &-item{
                &:not(:last-of-type){
                    margin-right: 20px;
                }
            }

            &-num{
                font-size: 24px;
                font-weight: bold;
            }

            &-label{
                font-size: 14px;
            }
        }
    }

    &-map{
        position: relative;
        
        width: 70%;
        max-width: 70%;
        margin-bottom: 30px;

        &__wrap{
            position: relative;

            width: 100%;
            padding-top: 100%;
        }

        &__container{
            position: absolute !important;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}