@import '../themes/light.scss';

html,
body {
	overflow-x: hidden;
}

html {
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-tap-highlight-color: transparent;
}

body {
	margin: 0;
	min-height: 100vh;
	text-decoration-skip: none;

}

iframe {
	border: 0;
}

main {
	display: block;
}

ul,
ol,
li {
	margin: 0;
    padding: 0;
	list-style: none;
}

li {
	display: block;
}

dl {
	margin-top: 0;
	margin-bottom: 0;
}

dd {
	margin-left: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: 0;
	font-size: inherit;
}

blockquote {
	margin: 0;
	padding: 0;
}

p {
	margin-top: 0;
	margin-bottom: 0;
}

sup {
	position: relative;
	top: -0.5em;
	vertical-align: baseline;
	font-size: 75%;
	line-height: 0;
}

strong {
	font-weight: bold;
}

figure {
	margin: 0;
}

fieldset {
	margin: 0;
	padding: 0;
	border: none;
}

img,
svg {
	max-width: 100%;
	height: auto;
	border: 0;
	vertical-align: middle;
}

a {
	color: inherit;
	text-decoration: none;
}

button {
	overflow: visible;
	margin: 0;
	padding: 0;
	border: 0;
	background: none;
	text-align: inherit;
	text-transform: inherit;
	letter-spacing: inherit;
	font: inherit;
	cursor: pointer;
	-webkit-font-smoothing: inherit;
}

button,
[type='text'],
[type='password'],
[type='submit'],
[type='search'],
[type='email'],
[type='tel'],
textarea {
	outline: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

::-moz-focus-inner {
	padding: 0;
	border: 0;
}

table {
	border-spacing: 0;
	border-collapse: collapse;
}

th {
	vertical-align: middle;
}

td {
	vertical-align: middle;
}

*,
*::before,
*::after {
	box-sizing: border-box;
	outline: 0;
	margin: 0;
}

input::-ms-clear,
input::-ms-reveal {
	display: none;
}

input[type='radio'],
input[type='checkbox'] {
	margin: 0;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
	display: none;
}

#titlediv {
	display: none;
}

