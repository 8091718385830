.SharingMessage{
    display: flex;
    align-items: center;

    margin-bottom: 20px;

    color: var(--dark-color);

    cursor: pointer;

    &__icon{
        width: 20px;
        margin-right: 16px;
    }

    &__label{
        font-size: 12px;
    }

    &:hover &__label{
        text-decoration: underline;
    }
}
