.sidebar-right{
    width: var(--sidebar-right-size-desktop);
    padding: 16px;

    &__block{
        &:not(:last-of-type){
            margin-bottom: 30px;
        }
        
    }

    &-list{
        display: flex;

        &__item{
            margin-right: 12px;

            cursor: pointer;
        }
    }
}