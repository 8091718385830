.stat{
    max-width: 1200px;
    margin-bottom: 20px;

    &__container{
        margin-bottom: 40px;
    }

    &-visitedCountries{
        &__list{
            display: flex;
            flex-wrap: wrap;

            padding: 0;
            margin: 0;

            list-style: none;
        }

        &__item{
            position: relative;

            display: flex;
            align-items: center;

            width: 25%;
            padding-left: 30px;
            margin-bottom: 12px;

            font-size: 15px;

            &::before{
                content: '';

                position: absolute;
                top: 1px;
                left: 0;
                
                width: 20px;
                height: 20px;

                border: 2px solid #333;
                border-radius: 3px;
            }

            &--checked{
                &::after{
                    content: '\2714'; 

                    color: white;
                    position: absolute;
                    top: 11px;
                    left: 5px;
                    transform: translateY(-50%);
                    font-size: 14px;
                    color: #333;
                }
            }
        }

        &__empty{
            margin-bottom: 20px;
        }

        &__flag{
            width: 25px;
            margin-right: 5px;
        }
    }

    &__description{
        font-size: 14px;
    }

    &-list{
        display: flex;
        flex-wrap: wrap;

        &__item{
            text-align: center;

            &:not(:last-of-type){
                margin-right: 30px;
            }

            &-img{
                position: relative;

                display: flex;
                align-items: center;
                justify-content: center;

                width: 100px;
                height: 100px;
                margin-bottom: 4px;

                line-height: 0;

                background-color: var(--input-placeholder-color);
                border-radius: 100%;

                img{
                    width: 60px;
                    height: 60px;
                }

                &--country{
                    img{
                        width: 100%;
                        height: 100%;

                        object-fit: cover;

                        border-radius: 100%
                    }
                }
            }

            &-percentage{
                position: absolute;
                bottom: -3px;
                z-index: -1;

                display: flex;

                width: 106px;
                height: 10%;

                overflow: hidden;

                &::before{
                    content: '';

                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;

                    height: 105px;
                    border-radius: 100%;
                    background-color: var(--secondary-color);
                }
            }

            &-count{
                position: absolute;
                top: -5px;
                right: -5px;

                display: flex;
                justify-content: center;
                align-items: center;

                width: 35px;
                height: 35px;

                font-weight: bold;
                color: var(--white);

                background-color: var(--primary-color);
                border-radius: 100%;
                border: 2px solid var(--background-color);
            }

            &-label{
                display: block;
                max-width: 100px;
                font-weight: bold;
            }
        }
    }

    &-temp-list{
        margin-bottom: 20px;

        &__item{
            margin: 40px 0;
            padding-left: 20px;
        }

        & h3{
            margin-bottom: 0;
        }
    }
}