

:root {
    --sidebar-left-size-desktop: 234px;
    --sidebar-right-size-desktop: 280px;
    --header-size-desktop: 84px;
}

body{
    font-family: var(--primary-font);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;

    background-color: var(--background-color);
}

.wrap{
    display: grid;
    grid-template-columns:  var(--sidebar-left-size-desktop) 1fr;
    min-height: 100vh;

    overflow: hidden;

    transition: .3s;

    @media (max-width: 1024px) {
        grid-template-columns: 200px 1fr;
    }

    &-sidebar{
        &--closed{
            grid-template-columns: 75px 1fr;
        }
    }
}
